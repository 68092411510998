@font-face {
  font-family: 'SofiaProBold';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('SofiaProBold'), url(../fonts/SofiaProBold.woff2) format('woff2'),
    url(../fonts/SofiaProBold.woff) format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaLight';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('ProximaNovaLight'), url(../fonts/SofiaProBold.woff2) format('woff2'),
    url(../fonts/SofiaProBold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaRegular';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('ProximaNovaRegular'), url(../fonts/proximanova-regular-webfont.woff2) format('woff2'),
    url(../fonts/proximanova-regular-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaSemibold';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('ProximaNovaSemibold'), url(../fonts/proximanova-semibold-webfont.woff2) format('woff2'),
    url(../fonts/proximanova-semibold-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNovaBold';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('ProximaNovaBold'), url(../fonts/proximanova-bold-webfont.woff2) format('woff2'),
    url(../fonts/proximanova-bold-webfont.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AquaNetworkHelvetica';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('AquaNetworkHelvetica'), url(../fonts/helvetica-flatrate-network.woff2) format('woff2'),
    url(../fonts/helvetica-flatrate-network.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'AquaNetworkHelveticaBold';
  font-display: fallback;
  unicode-range: U+000-5FF;
  src: local('AquaNetworkHelveticaBold'), url(../fonts/neue-helvetica-bold.woff2) format('woff2'),
    url(../fonts/neue-helvetica-bold.woff) format('woff');
  font-weight: normal;
  font-style: normal;
}

/* variables*/
:root {
  --background-color: #e8e8e8 0% 0% no-repeat padding-box;
  --primary-color: #0120c7;
}

ul {
  list-style: none;
  padding-left: 0;
}

html {
  height: 100%;
  font-family: 'ProximaNovaRegular', Verdana, sans-serif;
  font-display: fallback;
  font-style: normal;
  font-weight: normal;

  /* Adjust font size */
  font-size: 100%;
  -webkit-text-size-adjust: 100%;
  /* Font varient */
  font-variant-ligatures: none;
  -webkit-font-variant-ligatures: none;
  /* Smoothing */
  text-rendering: optimizeLegibility;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  text-shadow: rgba(0, 0, 0, 0.01) 0 0 1px;
}

body {
  background: #fff;
  color: #042b3b;
  cursor: auto;
  line-height: 1;
  margin: 0;
  padding: 0;
  position: relative;
  min-height: 100%;
  font-size: 100%;
}

:focus {
  outline-style: auto;
  outline-width: 0px !important;
}

a {
  color: #00bee8;
  transition: color 0.2s ease-in-out;
}

a:hover {
  text-decoration: none !important;
}

.anchor-button {
  font-size: 100%;
  font-family: inherit;
  border: 0;
  padding: 0;
  background: none;
}

h1 {
  color: var(--primary-color) !important;
}

h2 {
  color: var(--primary-color) !important;
}

strong {
  font-family: ProximaNovaBold, sans-serif;
  font-weight: 400 !important;
}

.desktop-container {
  padding-top: 55px;
  max-width: calc(100% - 420px);
}

.desktop-only-white {
  background: white !important;
}

.tablet-container {
  padding-top: 70px;
  max-width: calc(100% - 270px);
}

.mobile-container {
  min-height: calc(100% - 70px);
  margin: 0;
}

.wide-container {
  padding-top: 55px;
  max-width: calc(100% - 540px);
}

.hidden {
  display: none;
}

/*Utility*/

.aqua-network-blue {
  color: #00bee8 !important;
}

.aqua-network-red {
  color: #e33337 !important;
}

.aqua-network-gray {
  color: #869095 !important;
}

.aqua-gray {
  color: #4f5457 !important;
}

/*Modals*/

.center-modal {
  overflow: visible !important;
  width: auto;
  max-width: 960px !important;
}

@media only screen and (max-width: 767) {
  .modal-body {
    width: 100vw !important;
    padding: 0 15px !important;
    height: calc(100vh - 200px) !important;
  }
}

/*This handles animation for the modal*/

.modal-backdrop {
  background: rgba(255, 255, 255, 0.7) !important;
  opacity: 0.5 !important;
}

/* Modal Repeats */

.top-action-panel {
  display: flex;
  justify-content: flex-end;
}

.bottom-action-panel {
  font-size: 15px;
}

.leads-action-panel {
  font-size: 15px;
  justify-content: center !important;
}

.font-proxima {
  font-family: ProximaNovaRegular, sans-serif !important;
}

.font-proxima-light {
  font-family: ProximaNovaLight, sans-serif !important;
}

.font-proxima-semi {
  font-family: ProximaNovaSemibold, sans-serif !important;
}

.font-proxima-bold {
  font-family: ProximaNovaBold, sans-serif !important;
}

.font-aqua-network-helvetica {
  font-family: AquaNetworkHelvetica, sans-serif !important;
}

.font-aqua-network-helvetica-bold {
  font-family: AquaNetworkHelveticaBold, sans-serif !important;
}

.antialiased {
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon {
  color: #042b3b;
  display: inline-block;
  width: 1.25rem;
  height: 1.25rem;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
  vertical-align: middle;
}

strong,
b {
  font-family: 'ProximaNovaBold', sans-serif;
  font-weight: normal !important;
}

.icon-question-mark {
  width: 1.015625em;
}

.field-error {
  border: 1px solid red !important;
  background: rgba(255, 0, 0, 0.1) !important;
  color: red !important;
}

.field-error-message.message,
.field-success-message.message {
  font-size: 0.75rem;
  display: block;
  padding-top: 5px;
  clear: both;
}

.field-error-message {
  color: red;
}

.field-success-message {
  color: green;
}

.custom-error {
  color: red;
  margin-top: -1rem;
  font-size: 0.8rem;
  margin-bottom: 0.5rem;
}

.ladda-button.typicals.gray-button {
  display: block;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #042b3b;
  background: #fdfdfd;
  /* background: -webkit-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%); */
  background: -o-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%);
  background: linear-gradient(to bottom, #fdfdfd 27%, #dbdbdb 100%);
  border: 1px solid rgba(5, 43, 59, 0.27);
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(26, 24, 24, 0.08);
  width: 200px;
  margin: 0 auto;
  height: 40px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 28px;
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  text-transform: capitalize;
}

.gray-button {
  display: block;
  font-size: 16px;
  line-height: 28px;
  text-align: center;
  color: #042b3b;
  background: #fdfdfd;
  /* background: -webkit-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%); */
  background: -o-linear-gradient(top, #fdfdfd 27%, #dbdbdb 100%);
  background: linear-gradient(to bottom, #fdfdfd 27%, #dbdbdb 100%);
  border: 1px solid rgba(5, 43, 59, 0.27);
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(26, 24, 24, 0.08);
  width: 200px;
  margin: 0 auto;
  height: 40px;
  padding: 6px 10px;
  font-size: 13px;
  line-height: 28px;
  justify-content: space-between;
  display: flex;
  cursor: pointer;
  text-transform: capitalize;
}

.btn-hollow {
  min-width: 135px;
  padding: 0;
  height: 30px;
  line-height: 30px;
  display: inline-block;
  text-align: center;
  border: 1px solid #fff;
  border-radius: 3px;
  cursor: pointer;
  text-transform: uppercase;
  font-size: 0.8rem;
  background-color: #f0f0f0;
}

.btn-color-orange {
  border-color: #00bee8;
  color: #00bee8 !important;
}

.btn-color-orange:hover {
  background-color: #00bee8;
  color: white !important;
}

.flat-button {
  text-transform: capitalize;
  padding: 9px 10px;
  background-color: #00bee8;
  border-radius: 2px;
  color: #fff;
  text-shadow: 0 1px 1px #f5f5f5;
  text-rendering: optimizelegibility;
}

#typicalItemsPop {
  position: absolute;
  right: 30px;
  top: 7px;
}

/* NEW BUTTONS AND LINKS PER THE STYLE GUIDE  */
.btn-small {
  vertical-align: middle;
  text-align: center;
  height: 34px;
  min-width: 65px;
  border-radius: 3px;
  cursor: pointer;
  padding: 5px 0;
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 13px;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
}

.btn-primary {
  background: #00bee8;
  color: white !important;
  border: 1px solid #df7804;
}

.btn-large {
  vertical-align: middle;
  text-align: center;
  padding: 8px 30px;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
}

.primary {
  background: var(--primary-color);
  color: white !important;
  border: 1px solid var(--primary-color);
}

.secondary {
  background: var(--background-color);
  color: var(--primary-color) !important;
  border: 1px solid var(--background-color);
}

.icon-edit {
  color: var(--primary-color) !important;
}

.edit-button {
  color: var(--primary-color) !important;
}

.edit-button:hover {
  text-decoration: underline !important;
}

.primary-btn-small {
  vertical-align: middle;
  text-align: center;
  padding: 4px 30px;
  background: var(--primary-color);
  color: white !important;
  border: 1px solid var(--background-color);
  border-radius: 3px;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  height: 34px;
  text-transform: capitalize;
}

.leads-blurb {
  font-weight: 300;
  letter-spacing: 0.11px;
  line-height: 25px;
  text-align: center;
  font-size: 18px;
}

@media screen and (max-width: 992px) {
  .leads-dialog {
    max-height: 540px;
    max-width: 800px;
  }
}

@media screen and (min-width: 993px) {
  .leads-dialog {
    height: 540px;
    width: 800px;
  }
}

.leads-dialog {
  max-height: 540px;
  max-width: 800px;
}

.leads-title {
  font-size: 36px;
  padding-top: 16px;
  padding-bottom: 16px;
  margin: 0 6px 0 0;
  line-height: 24px;
  font-family: 'SofiaProBold';
  text-align: center;
}

.leads {
  text-align: center;
}

.leads-truck-div {
  width: 65px;
  height: 41px;
}

.leads-truck-img {
  width: 100%;
  height: 100%;
}

.leads-txt {
  width: 100%;
  padding-top: 10px;
  height: 50px;
  max-width: 400px;
  font-size: 18px;
  color: #042b3b;
  background: #e7e7e7;
  border: 1px solid white;
  padding: 8px 14px;
  box-shadow: none;
  font-family: 'ProximaNovaRegular', sans-serif;
  margin: 0 0 15px 0;
}

.leads-btn {
  width: 100%;
  vertical-align: middle;
  align-items: center !important;
  text-align: center;
  padding: 4px 30px;
  background: #000000;
  color: white !important;
  border: 1px solid #000000;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  white-space: nowrap;
  height: 50px;
  max-width: 400px;
  text-transform: capitalize;
  font-size: 18px;
}

.primary-btn-small:hover,
.primary-btn-small:active {
  background: var(--primary-color);
}

.primary-btn-large {
  vertical-align: middle;
  text-align: center;
  padding: 8px 30px;

  color: #0120c7 !important;
  border: 1px solid #e8e8e8;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
  background: var(--background-color);
}

.inventory-update-button > div > svg {
  vertical-align: top;
}

.primary-btn-large:hover,
.primary-btn-large:active {
  background: var(--background-color);
}

.secondary-btn-small {
  vertical-align: middle;
  text-align: center;
  padding: 0 30px;
  background: #f2f2f2;
  color: #0d2836 !important;
  border: 1px solid #bababa;
  border-radius: 3px;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
  height: 34px;
}

.secondary-btn-small:hover,
.secondary-btn-small:active,
.gray-button:hover,
.gray-button:active {
  background: #fafafa !important;
  border: 1px solid #b4b4b4;
}

.secondary-btn-large {
  vertical-align: middle;
  text-align: center;
  padding: 8px 30px;
  background: #f2f2f2;
  color: #0d2836 !important;
  border: 1px solid #bababa;
  border-radius: 3px;
  font-size: 18px;
  cursor: pointer;
  font-family: ProximaNovaRegular, sans-serif;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  text-transform: capitalize;
}

.secondary-btn-large:hover,
.secondary-btn-large:active {
  background: #fafafa;
  border: 1px solid #b4b4b4;
}

.unstyled-button {
  background: none;
  border: none;
}

.in-line-link {
  color: #00bee8;
}

.in-line-link:hover,
.in-line-link:active {
  border-bottom: 1px solid #00bee8;
  color: #00bee8;
}

.read-more {
  text-decoration: none;
  cursor: pointer;
  color: #0020c6;
  text-transform: uppercase;
  font-size: 0.8rem;
  border-bottom: 1px solid transparent;
}

.read-more:hover,
.read-more:active {
  border-bottom: 1px solid #0020c6;
  color: #0020c6;
}

.read-more:after {
  content: '➝';
  padding-left: 0.8em;
  vertical-align: middle;
}

.read-less {
  text-decoration: none;
  cursor: pointer;
  color: #ffffff;
  font-size: 1.5rem;
}

.read-less:hover,
.read-less:active {
  color: #ffffff;
}

.read-less:before {
  content: '⬅︎';
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  vertical-align: middle;
}

/* */

.inventory-page-icon-small {
  width: 40px;
  height: 40px;
}

@media screen and (max-width: 992px) {
  .inventory-large-block {
    display: none;
  }
  .item-custom .item-image {
    background: none;
    height: 10px;
  }
  .inventory-small-block {
    min-height: 40px;
  }
}
@media screen and (min-width: 993px) {
  .inventory-small-block {
    display: none !important;
  }
}

.item-image {
  background: url('/assets/images/inventory/custom-item.png') no-repeat center 30px;
  height: 110px;
  width: 100%;
}

.item-square .item-details .title {
  font-size: 0.8em;
  color: #042b3b;
  line-height: 0.8em;
}

.items-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(180px, 1fr));
  background: white;
  margin: 0;
}

.item-desc {
  font-size: 11px;
  color: #737677;
}

ul {
  list-style-type: none;
}

.room-title {
  font-family: 'ProximaNovaSemibold', sans-serif;
  font-size: 15px;
  padding: 10px;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  color: white;
  text-align: center;
  background-color: var(--primary-color);
  border-radius: 3px;
}

.item-custom {
  height: 260px;
  padding: 15px 15px 15px 15px;
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #dddddd;
  position: relative;
  background-color: #f2f4f4;
}

.item-details {
  padding-bottom: 10px;
}

.item-square {
  padding: 10px;
  position: relative;
  height: auto;
  border-radius: 5px;
  margin: 5px;
  border: 1px solid #d6d6d6;
  box-shadow: 0 0 5px #0000000d;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.icons-container {
  padding: 0;
  margin: 0;
  height: 20px;
  position: absolute;
  top: 7px;
  right: 10px;
  display: flex;
}

.plus-minus-holder {
  display: flex;
  margin: 0;
  padding: 0;
  justify-content: space-between;
  width: 100%;
  text-align: right;
  align-items: center;
}

.result {
  font-size: 24px;
  font-weight: 800;
  width: 50%;
  font-family: 'AquaNetworkHelveticaBold', sans-serif;
  font-size: 1.5em;
  line-height: 30px;
}

.desc {
  font-size: 0.75rem;
  color: #737677;
  line-height: 16px;
  min-height: 16px;
}

.uppercase {
  text-transform: uppercase;
}

.link {
  color: #e8333e;
  cursor: pointer;
  border-bottom: 1px solid transparent;
}
.link:hover {
  border-bottom: 1px solid #e8333e;
  color: #e8333e;
}

/* funnel repeats */

.funnel-header {
  width: 100%;
  background-color: #f2f2f2;
  color: #042b3b;
  min-height: 109px;
  padding: 15px;
}

.funnel-center-wrapper {
  width: 100%;
}

@media only screen and (max-width: 992px) {
  .funnel-center-wrapper {
    padding-top: 55px;
    border-bottom: 55px solid #1a3649;
    background-color: #fff;
  }
}

.funnel-wrapper {
  width: 100%;
  background-color: #f2f2f2;
  color: #042b3b;
}

.funnel-title {
  font-size: 23px;
  padding-top: 0;
  margin: 0 6px 0 0;
  line-height: 24px;
  font-family: 'AquaNetworkHelvetica', sans-serif;
}

.funnel-header-text {
  padding-top: 5px;
  font-size: 13px;
  line-height: normal;
  margin: 0;
}

.funnel-subtitle {
  font-size: 1em;
  margin-bottom: 0;
  font-family: 'AquaNetworkHelvetica', sans-serif;
}

@media only screen and (min-width: 81.3125em) {
  .funnel-title {
    font-size: 30px;
  }
  .funnel-header-text {
    font-size: 16px;
    padding-top: 5px;
  }
}

.cta-button {
  font-size: 24px;
  font-family: 'AquaNetworkHelvetica', sans-serif;
  display: block;
  text-align: center;
  border: 1px solid #bc5300;
  cursor: pointer;
  text-transform: uppercase;
  border-radius: 2px;
}

.filled {
  color: #fff;
  padding: 9px 10px 6px 10px;
  text-shadow: 0 1px 1px #e67200;
  text-rendering: optimizelegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: linear-gradient(to bottom, #f59400 0%, #f17000 100%);
}

.submit {
  padding: 6px 1.5em;
  vertical-align: top;
  line-height: 1em;
}

.cta-button.small {
  font-family: 'ProximaNovaRegular', sans-serif;
  font-size: 13px;
  line-height: 14px;
  text-shadow: none;
  -webkit-font-smoothing: inherit;
}

.cta-button:hover {
  color: white;
  background: linear-gradient(to bottom, #f59c00 0, #f37f00 100%);
  text-shadow: none !important;
}

/* forms */

input:focus,
input:active {
  outline-style: none;
  outline-width: 0px;
  border: 1px solid #37beeb !important;
}

.field-title {
  height: auto;
  font-family: 'ProximaNovaBold', sans-serif;
  font-weight: 800;
  font-size: 16px;
  line-height: 16px;
  text-transform: capitalize;
  padding-bottom: 8px;
  margin: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  margin-left: -5px;
}

h4.field-title {
  padding-top: 15px;
}

.columns {
  padding: 0 30px;
}

.selection {
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 3px;
  padding: 0 25px 0 20px;
  line-height: 34px;
  height: 34px;
  cursor: pointer;
  overflow: hidden;
  font-size: 13px;
}

.selection:active,
.selection:focus {
  border: 1px solid #37beeb !important;
}

.aqua-network-field,
.aqua-network-textbox,
.aqua-network-textarea {
  height: 34px;
  width: 250px;
  font-size: 13px;
  color: #042b3b;
  background: #fff;
  border: 1px solid #819ca1;
  padding: 8px 14px;
  box-shadow: none;
  border-radius: 2px;
  font-family: 'ProximaNovaRegular', sans-serif;
  margin: 0 0 15px 0;
}

.aqua-network-field:hover,
.aqua-network-textbox:hover,
.aqua-network-textarea:hover,
.aqua-network-field:focus,
.aqua-network-textbox:focus,
.aqua-network-textarea:focus {
  border-color: #042b3b;
}

.consultation-date {
  width: inherit;
  height: 34px;
  font-size: 13px;
  color: #042b3b;
  background: #fff;
  border: 1px solid #819ca1 !important;
  padding: 8px 14px;
  box-shadow: none;
  border-radius: 2px;
  font-family: 'ProximaNovaRegular', sans-serif;
  margin: 0 0 15px 0;
}

.city,
.address {
  width: 65%;
  margin-right: 2.5%;
}

.zip {
  width: 37.5%;
  margin-right: 2.5%;
}

.location-form .row.fields {
  border-bottom: 1px solid #d6d6d6;
  background: #fff;
}

.location-form .row:last-child {
  border: none;
}

.location-form .apt,
.location-form .state {
  width: 32.5%;
  height: 34px;
}

.form-row {
  margin: 6px;
  padding: 0;
  display: flex;
  justify-content: space-between;
}

.columns {
  margin: 0 30px;
}

.aqua-network-checkbox-container input[type='checkbox'] {
  margin: 1px 12px 0 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: white;
  cursor: pointer;
  border-radius: 0;
  border: solid 1px #9eafb4;
  padding: 7px;
  height: 15px;
}

.plan-ladda {
  margin-left: auto;
  margin-bottom: 5px;
}

.aqua-network-checkbox-container input[type='checkbox']:hover {
  border: solid 1px #042b3b;
}

.aqua-network-checkbox-container input[type='checkbox']:checked {
  background: #fff url('/assets/images/blue_checkmark.svg') no-repeat center center;
  border: 1px solid #cacaca;
}

.aqua-network-checkbox-container {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.disabled {
  color: #ababab;
  border-color: #ababab;
  background: #f0f0f0;
}
.aqua-network-textarea {
  height: 85px;
  line-height: 18px;
  padding: 10px 14px;
  resize: none;
}

.auth-error {
  text-align: center;
  color: red;
  font-size: 0.9rem;
  padding: 10px;
  font-weight: 100;
}

/*
BRAINTREE CREDIT CARD STYLING */

#card-number,
#cvv,
#expiration-month,
#expiration-year,
#postal-code {
  height: 34px;
  font-size: 13px;
  color: #042b3b;
  background: #fff;
  border: 1px solid #819ca1;
  padding: 8px 14px;
  -webkit-box-shadow: none;
  box-shadow: none;
  border-radius: 2px;
  font-family: 'ProximaNovaRegular', sans-serif;
  margin: 0 15px 0 0;
}

#card-number {
  width: 250px;
}

#card-number.braintree-hosted-fields-focused,
#cvv.braintree-hosted-fields-focused,
#expiration-month.braintree-hosted-fields-focused,
#expiration-year.braintree-hosted-fields-focused,
#postal-code.braintree-hosted-fields-focused {
  border-color: #777;
}

#card-number.braintree-hosted-fields-focused,
#cvv.braintree-hosted-fields-focused,
#expiration-month.braintree-hosted-fields-focused,
#expiration-year.braintree-hosted-fields-focused,
#postal-code.braintree-hosted-fields-focused {
  border-color: tomato;
}

#card-number.braintree-hosted-fields-focused,
#cvv.braintree-hosted-fields-focused,
#expiration-month.braintree-hosted-fields-focused,
#expiration-year.braintree-hosted-fields-focused,
#postal-code.braintree-hosted-fields-focused {
  border-color: limegreen;
}

.react-datepicker__navigation--next {
  border-left-color: #00728b !important;
}

.react-datepicker__navigation--previous {
  border-right-color: #00728b !important;
}

.react-datepicker__header {
  background-color: white !important;
  border-bottom: none !important;
}

.react-datepicker__month {
  border: solid 1px #999999;
}

.react-datepicker-popper {
  z-index: 100 !important;
}

/* Plan page repeats */

.section-header {
  padding-bottom: 10px;
}
.plan-section .section-title {
  display: inline-block;
  margin: 0;
  font-family: 'ProximaNovaBold', sans-serif;
  font-size: 20px;
}
.plan-section {
  padding-bottom: 50px;
}
.focused-input {
  border-color: #00bee8 !important;
  border-width: 2px !important;
}
.item-column {
  font-size: 13px;
  float: none !important;
  height: 46px;
  padding: 5px 15px;
  border-bottom: 1px solid #dbdbdb;
}

.item-price {
  font-size: 13px;
  font-family: 'AquaNetworkHelveticaBold', sans-serif;
}

.item-small {
  width: 35%;
  text-align: right;
  text-transform: uppercase;
}

.plan-page .section-content {
  background: #eff1f3;
  border: 1px solid #dbdbdb;
  padding: 20px;
}

.plan-page p {
  padding: 0;
  margin: 0;
  border: none;
  outline: none;
}

.plan-page .funnel-title {
  display: inline-block;
  vertical-align: middle;
  padding: 0;
  font-size: 1.8rem;
  line-height: inherit;
}

.two-columns-table .item-big {
  width: 65%;
  border-right: 1px solid #dbdbdb;
}

.two-columns-table {
  border-width: 1px 1px 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
  margin-bottom: 0;
}

/* Popover defaults */

.popover {
  box-sizing: border-box;
  border: solid 1px #d2d2d2;
  border-radius: 3px;
  background-color: #fff;
  padding: 0.5rem 0.8rem;
  max-width: 20rem;
  font-size: 0.875rem;
  box-shadow: 0 0.3125rem 0.625rem rgba(0, 0, 0, 0.2);
  z-index: 69420 !important;
}

.ns-popover-tooltip p {
  line-height: 1.25;
  font-size: 0.75rem;
}

.ns-popover-tooltip p:last-of-type {
  margin-bottom: 0;
}

.tooltip-content .actions {
  margin-top: 16px;
}

.tooltip-content .action {
  font-size: 0.9em;
  width: 100%;
}

.tooltip-close {
  right: 0;
  text-align: right;
}

@media screen and (min-width: 757px) {
  .tooltip-close {
    display: none;
  }
}

.close-reveal-modal {
  display: inline-block;
  position: static;
  width: 40px;
  font-size: 26px;
  line-height: 50px;
  color: #042b3b;
  text-align: center;
  top: 0.625rem;
  right: 1.375rem;
  cursor: pointer;
  font-weight: bold;
  border: none;
  background: transparent;
}

.bottom-action-panel {
  padding: 10px 15px;
  background: #fff;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 -3px 4px -4px rgba(0, 0, 0, 0.3);
}

.section-content {
  padding: 0;
  max-width: 62.5rem;
  margin: 0;
  width: 100%;
  -webkit-font-smoothing: antialiased;
}

.section-title {
  font-family: AquaNetworkHelveticaBold, sans-serif;
  font-size: 27px;
  line-height: 1;
  display: inline-block;
  padding-bottom: 8px;
  letter-spacing: -0.025em;
  margin-bottom: 15px;
}

.nf-section {
  padding: 25px 0.9375rem;
  margin: 0;
}

.arrival-date {
  width: 100%;
  height: 50px;
  padding-left: 37px;
}
.long-distance-delivery-date {
  height: 34px;
  width: 100%;
  border: 1px solid #819ca1;
}

.sit-datepicker-input {
  height: 34px;
  border: 1px solid #819ca1;
  width: 170px;
  padding-top: 7px;
}

.react-datepicker__triangle {
  display: none !important;
}

.react-datepicker__input-container {
  width: inherit;
}

/* Hey Karl, you really shouldn't set global styles for our datepicker. */

.react-datepicker__input-container > input {
  border: 1px solid #d6d6d6;
  border-radius: 3px;
}
.compare-datepicker {
  padding: 8px 14px !important;
  vertical-align: middle;
  width: 100%;
}

.react-datepicker__input-container > input {
  cursor: pointer;
  padding-top: 5px;
  padding-left: 40px;
  font-family: ProximaNova, sans-serif;
  font-weight: 200;
  caret-color: transparent;
}

.react-datepicker__input-container > .long-distance-delivery-date {
  border: 1px solid #819ca1;
}

.react-datepicker-wrapper {
  width: 100%;
}

input[type='date'] {
  -webkit-appearance: none !important;
}

.aqua-network-radio input[type='radio'] {
  margin: 0 12px 0 0;
  padding: 7px;
  display: inline-block;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff;
  cursor: pointer;
  border-radius: 100%;
  border: 1px solid #a0afb4;
}

.aqua-network-radio input[type='radio']:hover {
  border: 1px solid black;
}

.aqua-network-radio input[type='radio']:checked {
  background: url('/assets/images/radio-checked.png') -2px -2px no-repeat;
  border: 1px solid #2bbdec;
  font-family: ProximaNovaBold, sans-serif !important;
}

.aqua-network-radio-container .selected {
  font-family: ProximaNovaBold, sans-serif;
}

.aqua-network-radio-container {
  display: block;
  padding-left: 23px;
  cursor: pointer;
  position: relative;
  line-height: 18px;
  font-size: 14px;
}

.aqua-network-radio-container input {
  position: absolute;
  left: 0;
}

.aqua-network-radio label span {
  font-family: ProximaNovaRegular, sans-serif;
  font-size: 0.875rem;
  display: block;
  color: #91a4aa;
}

.continue-link {
  color: var(--primary-color) !important;
  text-decoration: underline;
  font-size: 18px;
  line-height: 1.25;
  cursor: pointer;
}

.replaceify input {
  width: 100%;
  min-height: 50px;
  color: #042b3b;
  border: 1px solid #d6d6d6;
  border-radius: 2px;
  padding: 10.5px 14px 10.5px 38px;
  font-size: 1rem;
  font-family: ProximaNovaRegular, sans-serif;
  font-weight: 200;
}

.replaceify > svg {
  position: absolute;
  width: 32px;
  height: 44px;
  left: 4px;
}

.icon.from-arrow svg {
  fill: #042b3b;
}

.icon.to-arrow svg {
  fill: #042b3b;
}

.icon.to-arrow svg {
  fill: #042b3b;
  height: 10px;
}

.geosuggest__suggests {
  position: relative;
}

.geosuggest__suggests--hidden {
  max-height: 0;
  overflow: hidden;
  border-width: 0 !important;
  padding: 0 !important;
}

.suggestions-dropdown {
  position: absolute;
  width: 100%;
  top: 50px;
  z-index: 5;
  background: #fff;
  border: solid #829ca1;
  border-width: 0 1px 1px;
  border-radius: 0 0 2px 2px;
  -webkit-transition: opacity 0.2s ease-in;
  -o-transition: opacity 0.2s ease-in;
  transition: opacity 0.2s ease-in;
  cursor: pointer;
  margin-bottom: 0;
  padding-bottom: 25px;
}

.suggestions-list {
  list-style: none;
  margin: 0;
}

.suggestions-item {
  position: relative;
  -webkit-transition: background 0.2s ease-in-out;
  -o-transition: background 0.2s ease-in-out;
  transition: background 0.2s ease-in-out;
  padding: 8px;
  font-size: 13px;
  line-height: 18px;
  border-bottom: 1px solid #e9e9e9;
  text-decoration: none;
  text-indent: 15px;
}

.suggestions-item::before {
  fill: #91a4aa;
}

.suggestions-item:hover {
  background-color: #e2e3e5;
}

.suggestions-item::before {
  content: '';
  display: block;
  width: 3px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: transparent;
  text-transform: none;
  -webkit-transition: background-color 0.25s;
  -o-transition: background-color 0.25s;
  transition: background-color 0.25s;
}

.suggestions-item::after {
  content: '';
  display: block;
  position: absolute;
  top: 9px;
  left: 5px;
  height: 15px;
  filter: brightness(6) hue-rotate(-70deg) saturate(0);
  width: 15px;
  background: url('/assets/images/ic_LocationAdd.svg');
  background-size: 15px 15px;
  background-repeat: no-repeat;
}

.suggestions-item:hover::after {
  filter: brightness(5) hue-rotate(26deg) saturate(0.9);
}

.suggestions-item:hover::before {
  background-color: #00bee8;
}

.icon.blank-pin {
  fill: #91a4aa;
  vertical-align: middle;
  margin-right: 4px;
  -webkit-transition: color 0.25s;
  -o-transition: color 0.25s;
  transition: color 0.25s;
  width: 12px;
}

.suggestions-item:hover svg {
  fill: #00bee8;
}

.geosuggest__item--active {
  background-color: #e2e3e5;
}

.geosuggest__item--active::after {
  filter: brightness(5) hue-rotate(26deg) saturate(0.9);
}

.geosuggest__item--active::before {
  background-color: #00bee8;
}

.geosuggest__item--active svg {
  fill: #00bee8;
}

.geosuggest ul::after {
  display: block;
  content: url(/assets/images/powered_by_google_on_white.png);
  position: absolute;
  left: -1px;
  width: calc(100% + 2px);
  height: 27px;
  text-align: end;
  padding: 4px 10px;
  background: white;
  border: 1px solid #829ca1;
  border-top: none;
}

.marketing-page h2,
.standard-text h2 {
  font-size: 2.8rem;
  font-weight: 700;
  font-family: ProximaNovaBold, sans-serif;
}
.marketing-page h3,
.standard-text h3 {
  font-size: 1.7rem;
  font-family: 'ProximaNovaBold', sans-serif;
}
.marketing-page h4,
.standard-text h4 {
  font-size: 1.5rem;
  font-family: 'ProximaNovaBold', sans-serif;
}
.marketing-page h5,
.standard-text h5 {
  font-size: 1.4rem;
  font-family: 'ProximaNovaBold', sans-serif;
}

.marketing-page.mobile h2 {
  font-size: 2rem;
  line-height: 2rem;
}
.marketing-page.mobile h3 {
  font-size: 1.2rem;
}
.marketing-page.mobile h4 {
  font-size: 1.2rem;
}
.marketing-page.mobile h5 {
  font-size: 1.2rem;
}

.icon-link > a {
  font-size: 12px;
  color: #337a8c !important;
  text-decoration: none;
  border-bottom: 1px dotted #337a8c;
}
.icon-link > a:hover {
  color: #2bbdec !important;
}
.sidebar-section {
  padding: 18px 8px;
}
.sidebar-toggle-arrow {
  font-size: 0.75rem;
  margin: auto 0;
  line-height: 2;
  cursor: pointer;
}

.compare-items-list > li:first-of-type > div {
  margin-top: 0 !important;
}

.route-section-header > a {
  height: 21px;
  display: inline;
  overflow: hidden;
  vertical-align: top;
  font-size: 14px;
  text-decoration: underline;
}
.no-style-button {
  -webkit-appearance: none;
  border: none;
  box-shadow: none;
  background: inherit;
  all: unset;
}

.flat-table {
  border-width: 1px 0 0 1px;
  border-style: solid;
  border-color: #dbdbdb;
  width: 100%;
  min-width: 640px;
}
.table-titles {
  font-family: 'ProximaNovaBold', sans-serif;
  font-size: 13px;
  height: 40px;
  vertical-align: middle;
  text-transform: uppercase;
  color: #042b3b;
  background: #eff1f3;
  border: 0 solid #dbdbdb;
  border-width: 1px 1px 1px 0;
}
.table-title {
  border-width: 0 1px 1px 0;
  border-style: solid;
  border-color: #dbdbdb;
  padding: 1rem;
  min-width: 165px;
  min-height: 40px;
  text-align: left;
  border-left: 1px solid #dbdbdb;
  font-size: 0.8125rem;
  line-height: 1.25;
  font-family: 'ProximaNovaBold', sans-serif;
}

.print-margins {
  margin: 50px;
}

@media print {
  .section-content {
    background-color: white !important;
  }
  .payment-protection {
    color: black !important;
  }
  .payment-protection:before {
    width: 0 !important;
  }
  .no-print,
  .edit-button,
  .profile-link,
  .plan-action-container,
  .lead,
  .small-map,
  i,
  .my-inventory-holder > button,
  .moving-insurance {
    display: none !important;
  }
  .support-number,
  .data {
    text-decoration: none !important;
  }
  .company-link {
    border: none !important;
  }
  thead th,
  thead td,
  tbody td {
    height: auto;
    padding: 12px;
  }
  .item-image {
    display: inline-block;
    padding: 0;
    margin: 0;
    vertical-align: middle;
    background: none;
    width: auto;
    height: auto;
  }
  .box-image {
    background: none;
    background-size: cover;
    display: inline-block;
    padding: 0;
    vertical-align: middle;
    width: auto;
    height: auto !important;
  }
  .item-title {
    display: inline-block;
    vertical-align: middle;
  }
  .item-desc {
    font-size: 9px;
    color: #8f8f8f;
  }
  .num {
    font-size: 13px;
  }
  .my-inventory-table th,
  td {
    display: table-cell !important;
  }
  .room-name {
    background-color: white !important;
    color: black !important;
    /* background-color: #28afe8;
    color: white; */
  }
  .inventory-breakdown-icon,
  .box-image-breakdown {
    width: 0 !important;
    background: none !important;
  }

  .item-text {
    left: 20px !important;
    top: 15px;
  }
  .printed-on {
    display: block !important;
    margin-bottom: 20px;
  }
  .item-details > .info {
    margin-left: 20px;
  }
  .suggested {
    background: white !important;
    color: white !important;
  }
  input[type='radio']:checked {
    filter: gray !important;
    -webkit-filter: grayscale(1) !important;
    filter: grayscale(1) !important;
    border-color: black !important;
  }
  a {
    color: black !important;
    text-decoration: none !important;
  }
}

.printed-on {
  display: none;
}

.ladda > svg {
  vertical-align: top !important;
}

.text-red {
  color: red !important;
}

/* compare now component  */

.compare-now {
  background: #76fffe;
  color: #0020c6;
}

.btn-color-primary-invert {
  background-color: white;
  border: 1px solid var(--primary-color) !important;
  color: var(--primary-color);
}

.btn-color-primary-invert:hover {
  background-color: var(--primary-color);
  color: white;
}
